<template>
<!-- eslint-disable -->
	<div class="mt-8">
		<h1 class="ml-8 mb-6">アイテムリスト</h1>
		<v-card
			v-for="item in this.itemList" :key="item.id"
			class="mx-auto mt-4 slideIn"
			max-width="350"
			height="80"
			elevation=5
		>
			<p class="text-center pt-5" style="font-size: 24px; margin-bottom: 0px;">{{ item }}</p>
		</v-card>
	</div>
</template>
<script>
/* eslint-disable */
import * as api from '@/api.js'
export default {
	name: 'ItemList',
    data () {
      return {
		  itemList: [],
     	}
    },

	methods: {
		load() {
			this.$store.dispatch('nowLoading', true)
			api.item.get().then((res) =>{
				switch(res.data.status){
					case 200:
						this.itemList = res.data.content
						break
					case 500:
						this.pop('エラー', res.data.error[0])
						break
				}
			}).catch(this.$store.dispatch('nowLoading', false))
		},
	},
	created() {
		this.load()
	},
}
</script>

<style scoped>
@keyframes SlideIn {
	0% {
		opacity: 0;
		transform: translateX(-64px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
} 
</style>
