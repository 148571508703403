import axios from "axios"

// ログイン
export const login = {
    login(param) {
        return axios({
            method: 'POST',
            url: '/login/login',
            params: param
        })
    },
    notification() {
        return axios({
            method: 'GET',
            url: '/login/notification',
        })
    },
}
// ユーザ登録
export const register = {
    register(param) {
        return axios({
            method: 'POST',
            url: '/register/register',
            params: param
        })
    },
}

// 通知内容設定
export const notification = {
    upload(param) {
        return axios({
            method: 'GET',
            url: '/notification/upload',
            params: param
        })
    },
}

// ユーザー設定
export const setting = {
    get() {
        return axios({
            method: 'GET',
            url: '/setting/get',
            // params: param,
        })
    },

    send(param) {
        return axios({
            method: 'POST',
            url: '/setting/send',
            params: param,
        })
    },

    hidden(param) {
        return axios({
            method: 'GET',
            url: '/setting/hidden',
            params: param,
        })
    },

}

// takeno
export const takeno = {
	get() {
        return axios({
            method: 'GET',
            url: '/takeno/get',
            // params: param
        })
    },

	list() {
        return axios({
            method: 'GET',
            url: '/takeno/list',
            // params: param
        })
    },

	gift(param) {
        return axios({
            method: 'GET',
            url: '/takeno/gift',
            params: param
        })
    },

	fire(param) {
        return axios({
            method: 'GET',
            url: '/takeno/fire',
            params: param
        })
    },

	shop() {
        return axios({
            method: 'GET',
            url: '/takeno/shop',
            // params: param
        })
    },

	purchase(param) {
        return axios({
            method: 'POST',
            url: '/takeno/purchase',
            params: param
        })
    },

	gacha() {
        return axios({
            method: 'GET',
            url: '/takeno/gacha',
            // params: param
        })
    },
}

// メモ帳
export const memo = {
	get() {
        return axios({
            method: 'GET',
            url: '/memo/get',
            // params: param
        })
    },
    upload(param) {
        return axios({
            method: 'GET',
            url: '/memo/upload',
            params: param
        })
    },
}

// アイテムリスト
export const item = {
	get() {
        return axios({
            method: 'GET',
            url: '/item/get',
            // params: param
        })
    },
}

// 日報取得
export const home = {
    get() {
        return axios({
            method: 'GET',
            url: '/home/get',
            // params: param
        })
    },
    search(param) {
        return axios({
            method: 'GET',
            url: '/home/search',
            params: param
        })
    },
    sort(param) {
        return axios({
            method: 'GET',
            url: '/home/sort',
            params: param
        })
    },

	getRank() {
        return axios({
            method: 'GET',
            url: '/home/rank',
            // params: param
        })
    },
    rankReset() {
        return axios({
            method: 'GET',
            url: '/home/reset',
            // params: param
        	        
        })
    },
    
}

// 日報更新
export const upload = {
    upload(param) {
        return axios({
            method: 'POST',
            url: '/upload/upload',
            params: param
        })
    },
}

// fire日報
export const fire = {
    item() {
        return axios({
            method: 'POST',
            url: '/fire/item',
            // params: param
        })
    },
}

// 投稿詳細
export const detail = {
    favorite(param) {
        return axios({
            method: 'GET',
            url: '/detail/favorite',
            params: param
        })
    },

    removeFav(param) {
        return axios({
            method: 'GET',
            url: '/detail/remove',
            params: param
        })
    },

    like(param) {
        return axios({
            method: 'GET',
            url: '/detail/like',
            params: param
        })
    },

    getGift(param) {
        return axios({
            method: 'GET',
            url: '/detail/getGift',
            params: param
        })
    },

    edit(param) {
        return axios({
            method: 'GET',
            url: '/detail/edit',
            params: param
        })
    },

    delete(param) {
        return axios({
            method: 'GET',
            url: '/detail/delete',
            params: param
        })
    },
}